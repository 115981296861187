<template>
    <v-container
        fluid
    >
        <vue-page-transition name="fade-in-left">
            <router-view/>
        </vue-page-transition>

    </v-container>
</template>
<script>
    export default {
        name: 'vwfiscalRules',
        data(){
            return {

            }
        },
        computed: {

        },
        methods: {

        },
        mounted(){

        }
    }
</script>